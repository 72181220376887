import React from 'react'
import logo from '../perzosoft.bmp';
import '../css/App.css';

export const Menu = () => {
 

  return (
   <div className="App-body">
      <div>
        <img src={logo} className="App-logo" alt="logo" />
     </div>
     <section className="seccion">
           <h1>Menú PerzoBanco</h1>

        </section>
        </div>
  )
}

export default Menu;
