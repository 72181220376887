import React from 'react'
import logo from '../perzosoft.bmp';

export const Cuentas = () => {
  return (
    <div className="App-body">
    <div>
      <img src={logo} className="App-logo" alt="logo" />
   </div>
    <section className= "seccion">
    <h1>Cuentas</h1>


<div>
<form>
  <div className='GrupoForm2'>
    <fieldset>
    <label>Clave: 001</label>
    <input className="clave" type='hidden' name='clave' value={1}/>
    </fieldset>

    <fieldset>
    <label>Empresa: 001 </label>
    <input className="" type='number' name='empresa' />
    </fieldset>

    <fieldset>
    <label>Cuenta: </label>
    <input className="" type='number' name='cuenta' />
    </fieldset>

    <fieldset>
    <label>Banco: </label>
    <input className="" type='text' name='banco' />
    </fieldset>

    <fieldset className='descripcion'>
    <label>Descripcion </label>
    <textarea className="textareadescripcion" type='number' name='descripcion' rows={8} cols="40"/>
    </fieldset>
    </div>
</form>
</div>
    </section>
    </div>
  )
}
