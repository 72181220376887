import React from 'react'
import logo from '../perzosoft.bmp';
import {Routes, Route, HashRouter, NavLink} from "react-router-dom";
import { Menu } from '../componentes/Menu';
import { Catalogos } from '../componentes/Catalogos';
import { Empresas } from '../componentes/Empresas';
import { Cuentas } from '../componentes/Cuentas';

export const Misrutas = () => {
  return (
   
    <>
    <HashRouter>
      {/* navegacion y header */}
    <header>
    <NavLink to= "/"><img src={logo} className="Nav-logo" alt="logo" /> </NavLink>
    
      <nav>
      <ul>
<li>
  <NavLink to="/catalogos">Catálogos</NavLink>
</li>
<li>
  <NavLink to="/movimientos">Movimientos</NavLink>
</li>
<li>
   <NavLink to="/reportes">Reportes</NavLink>
</li>
<li>
  <NavLink to="/procesos">Procesos</NavLink>
</li>
{/* menu catalogos */}
</ul>
      </nav>

    </header>
      {/* contenido central */}
      
      <div className='App'>
        
        <Routes>

          <Route path="/" element={<Menu />} />
          <Route path="/catalogos" element={<Catalogos />} />
          <Route path="/empresas" element={<Empresas />} />
          <Route path="/cuentas" element={<Cuentas />} />

        </Routes>
      </div>
      
      {/* Footer */}
      </HashRouter>
    </> 
   
  )
}
