import React from 'react'
import logo from '../perzosoft.bmp';

export const Empresas = () => {
  return (
    <div className="App-body">
    <div>
      <img src={logo} className="App-logo" alt="logo" />
   </div>
    <section className= "seccion">
    <h1>Empresas</h1>

<div>
<form>
  <div className='GrupoForm1'>
    <fieldset>
    <label>Clave: 001</label>
    <input className="clave" type='hidden' name='clave' value={1}/>
    </fieldset>

    <fieldset>
    <label>Nombre </label>
    <input className="" type='text' name='nombre' />
    </fieldset>

    </div>
</form>
</div>
    </section>
    </div>
  )
}
