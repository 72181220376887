import React from 'react';
import './css/App.css';
import './css/formulario.css';
import './css/Header.css';
import { Misrutas } from './router/Misrutas';

function App() {
  return (
   <>
   <Misrutas/>
   </>
            
       
    
  );
}

export default App;
